<template>
  <div class="sub-pilot-list">
    <div>
      <div class="card">
        <div class="card-body">
          <h4 class="header-title">All Pilots</h4>
          <router-link
            to="/admin/pilotAdd"
            v-if="['webadmin', 'zoneadmin'].includes(this.currentRole)"
          >
            <b-button variant="success" size="sm" class="ml-1 btn-add-pilot">
              <i class="mdi mdi-account-plus mr-1"></i>
              <span>Add a Pilot</span>
            </b-button>
          </router-link>
          <p class="text-muted font-13 mb-4"></p>
          <div class="row mb-md-2">
            <!-- Filters -->
            <div class="col-12">
              <!-- Filter by Zone -->
              <b-form-select
                v-model="selectedZone"
                class="col-sm-3 mb-3 mr-2"
                v-if="['webadmin'].includes(this.currentRole)"
              >
                <b-form-select-option :value="null">
                  Filter by Zone
                </b-form-select-option>
                <template v-for="item in zoneTable">
                  <b-form-select-option :key="item.id" :value="item.id">
                    {{ item.name }}
                  </b-form-select-option>
                </template>
              </b-form-select>

              <!-- Filter by Vendor -->
              <b-form-select
                v-model="selectedOperator"
                class="col-sm-3 mb-3 mr-2"
              >
                <b-form-select-option :value="null">
                  Filter by Operators
                </b-form-select-option>
                <template v-for="item in groupedOperatorsTable">
                  <b-form-select-option
                    :key="item.operator"
                    :value="item.operator"
                  >
                    {{ item.operator }} ({{ item.operatorData.length }})
                  </b-form-select-option>
                </template>
              </b-form-select>

              <!-- Filter by Pilot Type -->
              <b-form-select v-model="selectedPilotType" class="col-sm-3 mb-3">
                <b-form-select-option :value="null">
                  Filter by Pilot Type
                </b-form-select-option>
                <template v-for="item in pilotTypeTable">
                  <b-form-select-option
                    :key="item.pilot_type"
                    :value="item.pilot_type"
                  >
                    {{ item.pilot_type }} ( {{ item.value.length }} )
                  </b-form-select-option>
                </template>
              </b-form-select>
            </div>

            <!-- Search -->
            <!--
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            -->
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="pilotTable"
              :fields="fields"
              responsive="sm"
              :per-page="perPage"
              :current-page="currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:cell(avatar)="row">
                <img
                  :src="getImageUrl(row.item.user.avatar)"
                  :alt="row.item.user.name"
                />
              </template>

              <template v-slot:cell(user)="row">
                {{ row.value.name }}
                <span>{{ row.item.pilot_type }}</span>
              </template>

              <template v-slot:cell(operator)="row">
                {{ row.value.company_name }}
                <span>{{ row.value.zone.name }}</span>
              </template>

              <template v-slot:cell(cpy_stats_aggregate)="row">
                {{ row.value.aggregate.sum.total_flights || 0 }}
              </template>

              <template v-slot:cell(actions)="row">
                <a
                  @click="displayPilotProfile(row.item)"
                  title="View"
                  class="action-icon view"
                >
                  <i class="mdi mdi-eye"></i>
                </a>

                <a
                  @click="editPilotProfile(row.item)"
                  title="Edit"
                  class="action-icon edit"
                >
                  <i class="mdi mdi-square-edit-outline"></i>
                </a>
              </template>

              <template v-slot:row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">
                      {{ key }}: {{ value }}
                    </li>
                  </ul>
                </b-card>
              </template>
            </b-table>
          </div>
          <div class="row">
            <!-- # of entries per page selector -->
            <div class="col-12 col-sm-6 text-center text-sm-left mb-2 mb-sm-0">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="ml-2 mr-2"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="dataTables_paginate paging_simple_numbers">
                <b-pagination
                  v-model="currentPage"
                  class="justify-content-center justify-content-sm-end"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>

<script>
import {
  GET_ZONES,
  GET_PILOTS_FOR_LISTING_FILTER,
} from "@/graphql/queries/queries";
import _ from "lodash";
import { mapState } from "vuex";

const ZONES_ATTRIBUTE = "zone";
const PILOTS_ATTRIBUTE = "pilots";

export default {
  name: "pilotListing",
  props: {
    pilotsData: {
      type: Array,
    },
  },
  data() {
    return {
      selectedOperator: null,
      selectedZone: null,
      selectedPilotType: null,
      zoneTable: [],
      pilotTable: [],
      pilotList: [],
      filteredPilotList: [],
      groupedOperatorsTable: [],
      indiePilots: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "avatar", label: "Avatar", class: "avatar", sortable: false },
        { key: "user", label: "Pilot", class: "pilot-name", sortable: true },
        { key: "operator", tdClass: "operator", sortable: true },
        { key: "weight", label: "Weight", sortable: true },
        { key: "cpy_stats_aggregate", label: "Total Flights", sortable: true },
        { key: "actions", label: "actions" },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    ...mapState(["currentRole"]),
    rows() {
      return this.pilotTable.length;
    },

    pilotTypeTable() {
      //grouping for pilot type
      if (this.selectedOperator === null) {
        return this.groupPilotTypeList(this.pilotList);
      } else if (this.selectedOperator === "Independent") {
        return this.groupPilotTypeList(this.indiePilots);
      } else {
        let pilotTypeList = this.pilotList.filter(
          (a) => a.operator.company_name === this.selectedOperator
        );
        return this.groupPilotTypeList(pilotTypeList);
      }
    },
  },
  watch: {
    selectedOperator() {
      this.filterList();
    },
    selectedZone() {
      this.filteredPilotList = this.pilotList;
      this.filterList();
    },
    selectedPilotType() {
      this.filterList();
    },

    pilotsData() {
      this.pilotTable = this.pilotsData;
      this.filterList();
    },
    filteredPilotList() {
      // pilot grouping
      const groups = _.chain(this.filteredPilotList)
        .groupBy("operator.company_name")
        .toPairs()
        .map((pair) => _.zipObject(["operator", "operatorData"], pair))
        .value();

      // separate independent pilots in a single list
      this.indiePilots = _.chain(groups)
        .filter((d) => d.operatorData.length === 1)
        .flatMap("operatorData")
        .value();

      // other pilots
      this.groupedOperatorsTable = _.filter(
        groups,
        (d) => d.operatorData.length > 1
      );

      // add indie pilots to this object
      this.groupedOperatorsTable.push({
        operator: "Independent",
        operatorData: this.indiePilots,
      });
      this.groupedOperatorsTable = _.orderBy(
        this.groupedOperatorsTable,
        "operatorData",
        "desc"
      );

      // //grouping for pilot type
      // const pilotTypeGroup = _.chain(this.pilotList)
      //   .groupBy("pilot_type")
      //   .toPairs()
      //   .map(pair => _.zipObject(["pilot_type", "value"], pair))
      //   .value();
      // this.pilotTypeTable = pilotTypeGroup;
    },
  },
  mounted() {
    this.filteredPilotList = this.pilotList;
    this.pilotTable = this.pilotsData;
    // Set the initial number of items
    this.totalRows = this.pilotTable.length;
  },
  methods: {
    groupPilotTypeList(list) {
      return _.chain(list)
        .groupBy("pilot_type")
        .toPairs()
        .map((pair) => _.zipObject(["pilot_type", "value"], pair))
        .value();
    },
    getImageUrl(url) {
      return url || "/img/default-avatar.jpg";
    },
    displayPilotProfile(rowItem) {
      this.$router.push({
        name: "pilotProfile",
        params: { pilotID: rowItem.pilot_id },
      });
    },
    editPilotProfile(rowItem) {
      this.$router.push({
        name: "pilotAdd",
        params: { pilotID: rowItem.pilot_id },
      });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    filterList() {
      this.pilotTable = this.pilotsData;
      if (
        this.selectedPilotType != null &&
        this.selectedOperator != null &&
        this.selectedZone != null
      ) {
        if (this.selectedOperator === "Independent") {
          this.pilotTable = this.pilotTable.filter(
            (a) =>
              this.indiePilots.find(
                (item) => item.operator.company_name === a.operator.company_name
              ) &&
              a.operator.zone.id === this.selectedZone &&
              a.pilot_type === this.selectedPilotType
          );
        } else {
          this.pilotTable = this.pilotTable.filter(
            (a) =>
              a.operator.company_name === this.selectedOperator &&
              a.operator.zone.id === this.selectedZone &&
              a.pilot_type === this.selectedPilotType
          );
        }
      }
      if (this.selectedOperator !== null) {
        if (this.selectedOperator === "Independent") {
          this.pilotTable = this.pilotTable.filter((a) =>
            this.indiePilots.find(
              (item) => item.operator.company_name === a.operator.company_name
            )
          );
        } else {
          this.pilotTable = this.pilotTable.filter(
            (a) => a.operator.company_name === this.selectedOperator
          );
        }
      }
      if (this.selectedZone !== null) {
        this.filteredPilotList = this.pilotList.filter(
          (a) => a.operator.zone.id === this.selectedZone
        );
        this.pilotTable = this.pilotTable.filter(
          (a) => a.operator.zone.id === this.selectedZone
        );
      }
      if (this.selectedPilotType !== null) {
        this.pilotTable = this.pilotTable.filter(
          (a) => a.pilot_type === this.selectedPilotType
        );
      }
      if (this.selectedOperator !== null && this.selectedZone !== null) {
        if (this.selectedOperator === "Independent") {
          this.pilotTable = this.pilotTable.filter(
            (a) =>
              this.indiePilots.find(
                (item) => item.operator.company_name === a.operator.company_name
              ) && a.operator.zone.id === this.selectedZone
          );
        } else {
          this.pilotTable = this.pilotTable.filter(
            (a) =>
              a.operator.company_name === this.selectedOperator &&
              a.operator.zone.id === this.selectedZone
          );
        }
      } else if (
        this.selectedOperator !== null &&
        this.selectedPilotType !== null
      ) {
        if (this.selectedOperator === "Independent") {
          this.pilotTable = this.pilotTable.filter(
            (a) =>
              this.indiePilots.find(
                (item) => item.operator.company_name === a.operator.company_name
              ) && a.pilot_type === this.selectedPilotType
          );
        } else {
          this.pilotTable = this.pilotTable.filter(
            (a) =>
              a.operator.company_name === this.selectedOperator &&
              a.pilot_type === this.selectedPilotType
          );
        }
      } else if (
        this.selectedZone !== null &&
        this.selectedPilotType !== null
      ) {
        this.pilotTable = this.pilotTable.filter(
          (a) =>
            a.operator.zone.id === this.selectedZone &&
            a.pilot_type === this.selectedPilotType
        );
      }
    },

    updateTable() {
      this.pilotTable = this.pilotsData;
    },
  },
  activated() {
    this.$apollo.queries.zoneTable.refetch();
  },
  apollo: {
    pilotList: {
      // graphql query
      query: GET_PILOTS_FOR_LISTING_FILTER,
      update(data) {
        return data[PILOTS_ATTRIBUTE];
      },
      error(error) {
        const errorMessage = JSON.stringify(error.message);
        console.error(errorMessage);
      },
    },
    zoneTable: {
      // graphql query
      query: GET_ZONES,
      update(data) {
        this.dataReady = true;
        return data[ZONES_ATTRIBUTE];
      },
      error(error) {
        this.error = JSON.stringify(error.message);
        console.error(this.error);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/css/sass_files";

.sub-pilot-list {
  table.table {
    color: #6c757d;
  }

  td.pilot-name {
    font-weight: 600;
  }

  td.operator span,
  td.pilot-name span {
    display: block;
    font-size: 13px;
    color: #98a6ad;
    font-weight: 400;
  }

  .avatar img {
    width: 52px;
    height: 52px;
    border-radius: 52px;
    border: #fff 2px solid;
    box-shadow: #000 0px 0px 2px;
  }

  .action-icon {
    display: inherit;
    padding: 0 5px;
    font-size: 1.2rem;
    color: #98a6ad;
    cursor: pointer;
  }

  .action-icon:hover {
    color: #6c757d;
  }

  h4.header-title {
    display: inline-block;
    vertical-align: sub;
    margin-right: 30px;
  }
}
</style>
